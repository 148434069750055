import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderPost from "../components/HeaderPost"
import FooterGlobal from "../components/FooterGlobal"
import Subscribe from "../components/Subscribe"
import BookStrategyBtnPrimary from "../components/BookStrategyBtnPrimary"
import ReadyStarted from "../components/ReadyStarted"
import MainNav from "../components/MainNav"

export const GetSinglePostData = graphql`
  query GetSinglePost($slug: String) {
    wpgraphql_greatweb {
      postBy(slug: $slug) {
        id
        title
        content
        date
        featuredImage {
          node {
            sourceUrl
          }
        }
        postSiteMetaDescription_acf {
          siteMetaDescription
        }
        postSiteMetaTitle_acf {
          sitemetatitle
        }
      }

      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
    }
  }
`

const Post = ({ data }) => {
  const {
    wpgraphql_greatweb: {
      postBy: {
        title,
        content,
        date,
        featuredImage: {
          node: { sourceUrl },
        },
        postSiteMetaDescription_acf: { siteMetaDescription },
        postSiteMetaTitle_acf: { sitemetatitle },
      },

      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
    },
  } = data

  return (
    <Layout
      announcement={!announcement ? "" : announcement}
      countdownData={!countdownData ? "" : countdownData}
    >
      <MainNav active="blog" />
      <SEO
        title={sitemetatitle}
        description={siteMetaDescription}
        featuredImage={sourceUrl}
      />
      <HeaderPost
        heading={title}
        subheading=""
        date={date}
        featuredImage={sourceUrl}
        alt={title}
      />
      <div className="blog-single container">
        <div
          className="blog-single__article"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>

        <ReadyStarted color="primary" />
        <BookStrategyBtnPrimary />
        <Subscribe />
      </div>

      <FooterGlobal
        companyAddress={companyAddress}
        companyContactNumber={companyContactNumber}
        companyOverview={companyOverview}
        facebookLink={facebookLink}
        linkedinLink={linkedinLink}
        supportEmail={supportEmail}
        twiiterLink={twiiterLink}
      />
    </Layout>
  )
}

export default Post
