import { Link } from "gatsby"
import Moment from "react-moment"
import React from "react"
// import PropTypes from "prop-types"
const HeaderPost = ({
  heading,
  subheading,
  active,
  date,
  featuredImage,
  alt,
}) => {
  const color1 = "to right bottom, rgba(0, 0, 0, 0.9)"
  const color2 = "rgba(0, 0, 0, 0.8)"
  // const str = "linear-gradient(" + color1 + "," + color2 + "),"

  // const customStyle = {
  //   backgroundImage: str + "url(" + featuredImage + ")",
  //   backgroundRepeat: "no-repeat",
  // }

  return (
    <header className="header-secondary container">
      <div className="header-secondary__wrapper  ">
        <div className="nav__right--icon">
          <i className="fas fa-align-right"></i>
        </div>

        <div className="hero-post">
          {/* <h1 className="hero__heading">
            Business
            <span className="hero__heading--highlight">Website</span>
            That Drives Traffic and Get Better Results
          </h1> */}
          <h1 className="hero-post__heading">
            {heading}
            {/* We Build More Than Just a Website */}
          </h1>
          <h2 className="hero-post__subheading">
            {subheading}
            {/* It's great to have a pretty website, but it;s even better if that
              website is bringing in more money right? */}
          </h2>
          <p className="hero-post__date">
            <i className="hero-post__date--icon far fa-calendar-alt"></i>
            {date && <Moment format="MMMM DD, YYYY">{date}</Moment>}
          </p>
          <div className="hero-post__imgWrapper">
            <img src={featuredImage} alt={alt} className="hero-post__img" />
          </div>
        </div>
      </div>
    </header>
  )
}

// HeaderPost.propTypes = {
//   siteTitle: PropTypes.string,
// }

// HeaderPost.defaultProps = {
//   siteTitle: ``,
// }

export default HeaderPost
