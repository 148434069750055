import React, { useState } from "react"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { FaCheckCircle } from "react-icons/fa"
const Subscribe = () => {
  // const handleNewsletter = e => {
  //   e.preventDefault()

  //   // Lets track that custom click
  //   trackCustomEvent({
  //     // string - required - The object that was interacted with (e.g.video)
  //     category: "newsletter",
  //     // string - required - Type of interaction (e.g. 'play')
  //     action: "Click",
  //     // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
  //     label: "Newsletter",
  //   })
  // }

  const [subscribeValues, setSubscribeValues] = useState({
    email: "",
    Fname: "",
    result: "",
    loading: false,
  })

  const { email, Fname, result, loading } = subscribeValues

  const handleChange = name => e => {
    setSubscribeValues({
      ...subscribeValues,
      [name]: e.target.value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (email && Fname) {
      setSubscribeValues({
        ...subscribeValues,
        loading: true,
      })
      const result = await addToMailchimp(email, { Fname })
      if (result.result === "success") {
        setSubscribeValues({
          ...subscribeValues,
          result: result,
          loading: false,
        })
      } else {
        setSubscribeValues({
          ...subscribeValues,
          result: result,
          loading: false,
        })
      }
    } else {
      console.log(`fields is empty`)
    }
  }

  return (
    <>
      <div className="subscribe-secondary container my-4">
        <div className="subscribe-secondary__wrapper ">
          <h2 className="subscribe-secondary__heading">
            Join 20,000+ Others We Share Internet Marketing Secrets With
          </h2>
          <p className="subscribe-secondary__subheading">
            Subscribe to get the best digital marketing insights, strategies and
            tips delivered straight to your inbox.
          </p>

          {result === "" && (
            <form className="subscribe-secondary__form" onSubmit={handleSubmit}>
              <input
                className="subscribe-secondary__input"
                type="text"
                placeholder="Your First Name"
                value={Fname}
                onChange={handleChange("Fname")}
              />
              <input
                className="subscribe-secondary__input"
                type="email"
                placeholder="Your Best Email"
                value={email}
                onChange={handleChange("email")}
              />
              <button
                className={` ${
                  email && Fname
                    ? "subscribe-secondary__submitBtn btn"
                    : "subscribe-secondary__submitBtn btn-disable"
                }`}
                disabled={loading}
              >
                {loading ? "Submitting..." : "Subscribe"}
                <i className="subscribe-secondary__submitBtn--icon fas fa-arrow-right"></i>
              </button>
            </form>
          )}

          {result.result === "success" && (
            <div className="subscribe-secondary__resultWrapper">
              <FaCheckCircle className="subscribe-secondary__icon" />
              <p className="subscribe-secondary__result">{result.msg}</p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Subscribe
